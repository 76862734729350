import { ActionTree } from 'vuex'
import store, { RootState } from '@/store'
import { State } from './_state'
import { MutationsNames } from '@/modules/endless-aisle/store/_mutations'
import { GetterNames } from '@/modules/endless-aisle/store/_getters'
import {
  FilterModalElementE,
  GetModelsFiltersE,
  GetSessionElementD,
  SelectedFiltersItemE,
  SelectedFiltersPayloadE,
  SetSessionItemBulkD,
  SetSessionItemD,
  StepPageE,
  StockD,
} from './index.d'
import { SessionKeys } from '@/addons/enums'
import { catalogApi, endlessAisleApi } from '@/addons/axios'
import { ConfigGetters } from '@/store/configs-store'
import { GetModelsAttributes, GetModelsData } from '@/api'
import { handleCatchRequest } from '@/modules/endless-aisle/helpers/helpers'
import { NotificationsActions } from '@/store/notifications-store'
import I18n from '@/addons/i18n'
import { LedWallEntitiesT, LedWallEntityD } from '@/modules/fling/index.d'
export enum ActionsNames {
  SET_SHOW_ENDLESS_AISLE = 'set-show-endless-aisle',
  SET_OPENED_DRAWER_OR_MODAL = 'set-opened-drawer-or-modal',
  SET_OPENED_DRESSING_ROOM = 'set-opened-dressing-room',
  SET_SESSION_ITEM = 'set-session-item',
  SET_SESSION_ITEM_BULK = 'set-session-item-bulk',
  REMOVE_SESSION_ITEM = 'remove-session-item',
  REMOVE_SESSION_ITEM_BULK = 'remove-session-item-bulk',
  GET_REQUEST_SKU = 'get-request-sku',
  GET_REQUEST_YMAL_SKU = 'get-request-yml-sku',
  SET_SHOW_LOADER_FULL = 'set-show-loader-full',
  SET_STEP_PAGE = 'set-step-page',
  GET_FILTERS = 'get-filters',
  GET_CATEGORIES = 'get-categories',
  GET_MODELS = 'get-models',
  SET_OPENED_FILTERS_MODAL = 'set-opened-filters-modal',
  SET_SELECTED_FILTERS = 'set-selected-filters',
  REMOVE_FILTER_TAG = 'remove-filter-selected',
  SET_OPENED_PRODUCT_INFO_MODAL = 'set-opened-product-info-modal',
  GET_YMAL_PRODUCTS = 'get-ymal-products',
  SET_ACTIVE_FILTER_MODAL = 'set-active-filter-modal',
  RESET_ACTIVE_FILTER_MODAL = 'reset-active-filter-modal',
  SET_APPLIED_FILTER_MODAL = 'set-applied-filters-modal',
  RESET_ENDLESS_AISLE = 'reset-endless-aisle',
  SET_DRESSING_ROOM_INFO = 'set-dressing-room-info',
  CLOSE_ENDLESS_AISLE = 'close-endless-aisle',
  SET_ELEMENT_TO_SELL = 'set-element-to-sell',
  SET_TEMP_DRESSING_ROOM = 'set-temp-dressing-room',
  REMOVE_TEMP_DRESSING_ROOM = 'remove-temp-dressing-room',
  SET_CURRENT_PRODUCT = 'set-current-product',
  SET_PREV_PRODUCT = 'set-prev-product',
  STOCK_REQUEST = 'stock-request',
  SET_TEMP_PRODUCTS = 'set-temp-product',
  SET_LEDWALL_INFO = 'set-ledwall-info',
  RESET_LEDWALL_INFO = 'reset-ledwall-info',
}

export interface Actions {
  [ActionsNames.SET_SHOW_ENDLESS_AISLE](payload: boolean): void
  [ActionsNames.SET_OPENED_DRAWER_OR_MODAL](payload: boolean): void
  [ActionsNames.SET_OPENED_DRESSING_ROOM](payload: boolean): void
  [ActionsNames.SET_SESSION_ITEM](payload: SetSessionItemD): Promise<void>
  [ActionsNames.REMOVE_SESSION_ITEM](payload: SetSessionItemD): Promise<void>
  [ActionsNames.REMOVE_SESSION_ITEM_BULK](
    payload: SetSessionItemBulkD
  ): Promise<void>
  [ActionsNames.SET_SESSION_ITEM_BULK](
    payload: SetSessionItemBulkD
  ): Promise<void>
  [ActionsNames.GET_REQUEST_SKU](payload: { sku: string }): Promise<void>
  [ActionsNames.SET_SHOW_LOADER_FULL](payload: boolean): void
  [ActionsNames.SET_STEP_PAGE](payload: StepPageE): void
  [ActionsNames.GET_FILTERS](payload: { filterCategory: string }): Promise<void>
  [ActionsNames.GET_CATEGORIES](): Promise<void>
  [ActionsNames.GET_MODELS](payload: GetModelsFiltersE): Promise<GetModelsData>
  [ActionsNames.SET_OPENED_FILTERS_MODAL](payload: boolean): void
  [ActionsNames.SET_SELECTED_FILTERS](payload: SelectedFiltersPayloadE): void
  [ActionsNames.REMOVE_FILTER_TAG](payload: SelectedFiltersItemE): void
  [ActionsNames.SET_OPENED_PRODUCT_INFO_MODAL](payload: boolean): void
  [ActionsNames.GET_YMAL_PRODUCTS](payload: string): Promise<void>
  [ActionsNames.SET_ACTIVE_FILTER_MODAL](payload: {
    context: string
    remove: boolean
    value: FilterModalElementE
  }): void
  [ActionsNames.RESET_ACTIVE_FILTER_MODAL](): void
  [ActionsNames.SET_APPLIED_FILTER_MODAL](payload: boolean): void
  [ActionsNames.RESET_ENDLESS_AISLE](): void
  [ActionsNames.SET_DRESSING_ROOM_INFO](payload: { pk_consumer: string }): void
  [ActionsNames.CLOSE_ENDLESS_AISLE](): void
  [ActionsNames.SET_ELEMENT_TO_SELL](payload: Array<string>): void
  [ActionsNames.SET_TEMP_DRESSING_ROOM](payload: SetSessionItemD): Promise<void>
  [ActionsNames.REMOVE_TEMP_DRESSING_ROOM](): Promise<void>
  [ActionsNames.SET_CURRENT_PRODUCT](payload: {
    product: GetModelsAttributes
    variant?: string
  }): void
  [ActionsNames.SET_PREV_PRODUCT](payload: GetModelsAttributes): void
  [ActionsNames.STOCK_REQUEST](payload: string): Promise<Array<StockD>>
  [ActionsNames.SET_TEMP_PRODUCTS](payload: Array<GetModelsAttributes>): void
  [ActionsNames.SET_LEDWALL_INFO](payload: {
    value: LedWallEntitiesT | LedWallEntityD
    context: string
  })
  [ActionsNames.RESET_LEDWALL_INFO](): void
}

export const actions: ActionTree<State, RootState> = {
  /**
   * @Actions
   * @description Action for set showEndlessAisle
   * @ReferGetters GetterNames.SHOW_ENDLESS_AISLE
   * @payload boolean
   * @type void
   * @return void
   * */
  [ActionsNames.SET_SHOW_ENDLESS_AISLE](context, payload: boolean): void {
    context.commit(MutationsNames.SHOW_ENDLESS_AISLE, payload)
  },
  /**
   * @Actions
   * @description Action for set openedDrawerOrModal
   * @ReferGetters GetterNames.OPENED_DRAWER_OR_MODAL
   * @payload boolean
   * @type void
   * @return void
   * */
  [ActionsNames.SET_OPENED_DRAWER_OR_MODAL](context, payload: boolean): void {
    context.commit(MutationsNames.SET_OPENED_DRAWER_OR_MODAL, payload)
  },
  /**
   * @Actions
   * @description Action for set openedDressingRoom
   * @ReferGetters GetterNames.OPENED_DRAWER_OR_MODAL
   * @payload boolean
   * @type void
   * @return void
   * */
  [ActionsNames.SET_OPENED_DRESSING_ROOM](context, payload: boolean): void {
    context.commit(MutationsNames.SET_OPENED_DRESSING_ROOM, payload)
  },
  /**
   * @Actions
   * @description Action for set item ib session, based on pk_consumer and sku
   * @payload SetSessionItemD
   * @type void
   * @return void
   * */
  async [ActionsNames.SET_SESSION_ITEM](
    context,
    payload: SetSessionItemD & { session_id: SessionKeys }
  ): Promise<void> {
    const dr = await context.getters[GetterNames.GET_SESSION_ITEM]
    const usr = dr[payload?.pk_consumer || '']
    if (!usr) {
      dr[payload.pk_consumer || ''] = [
        {
          sku: payload.sku,
          quantity: 1,
        },
      ]
      await sessionStorage.setItem(
        payload.session_id || SessionKeys.DRESSING_ROOM,
        JSON.stringify(dr)
      )
      context.commit(MutationsNames.SET_DRESSING_ROOM_ELEMENTS, dr)
    } else {
      const findIsSkuExist = usr?.find(
        (el: GetSessionElementD) => el.sku === payload.sku
      )

      if (findIsSkuExist) {
        const index = usr?.indexOf(findIsSkuExist)
        findIsSkuExist.quantity = findIsSkuExist.quantity + 1
        dr[payload.pk_consumer || ''].splice(index, 1, findIsSkuExist)
      } else {
        dr[payload.pk_consumer || ''].push({
          sku: payload.sku,
          quantity: 1,
        })
      }
      await sessionStorage.setItem(
        payload.session_id || SessionKeys.DRESSING_ROOM,
        JSON.stringify(dr)
      )
      context.commit(MutationsNames.SET_DRESSING_ROOM_ELEMENTS, dr)
    }
  },
  /**
   * @Actions
   * @description Action for remove item ib session, based on pk_consumer and sku
   * @payload SetSessionItemD
   * @type void
   * @return void
   * */
  async [ActionsNames.REMOVE_SESSION_ITEM](
    context,
    payload: SetSessionItemD
  ): Promise<void> {
    const dr = await context.getters[GetterNames.GET_SESSION_ITEM]
    const pk_consumer = payload?.pk_consumer || ''
    if (!payload.sku) {
      delete dr[pk_consumer]
    } else {
      const usr = dr[pk_consumer]
      const findIsSkuExist = usr?.find(
        (el: GetSessionElementD) => el.sku === payload.sku
      )
      if (payload.removeAll || findIsSkuExist) {
        const index = usr?.indexOf(findIsSkuExist)
        if (payload.removeAll || findIsSkuExist.quantity === 1) {
          dr[pk_consumer].splice(index, 1)
          if (!dr[pk_consumer].length) {
            delete dr[pk_consumer]
          }
        } else {
          findIsSkuExist.quantity = findIsSkuExist.quantity - 1
          dr[pk_consumer].splice(index, 1, findIsSkuExist)
        }
      }
    }
    await sessionStorage.setItem(
      payload.session_id || SessionKeys.DRESSING_ROOM,
      JSON.stringify(dr)
    )
    context.commit(MutationsNames.SET_DRESSING_ROOM_ELEMENTS, dr)
  },
  /**
   * @Actions
   * @description Action for remove skus bulk, based on pk_consumer and sku
   * @payload SetSessionItemBulkD
   * @type void
   * @return void
   * */
  async [ActionsNames.REMOVE_SESSION_ITEM_BULK](
    context,
    payload: SetSessionItemBulkD
  ): Promise<void> {
    const dr = await context.getters[GetterNames.GET_SESSION_ITEM]
    await sessionStorage.removeItem(
      payload.session_id || SessionKeys.DRESSING_ROOM
    )
    if (payload.sku.length) {
      const pk_consumer = payload?.pk_consumer || ''
      const usr = dr[pk_consumer]
      payload.sku.forEach((s) => {
        const findIsSkuExist = usr?.find(
          (el: GetSessionElementD) => el?.sku === s?.sku
        )
        if (findIsSkuExist) {
          const index = usr?.indexOf(findIsSkuExist)
          dr[pk_consumer].splice(index, 1)
          if (!dr[pk_consumer].length) {
            delete dr[pk_consumer]
          }
        }
      })
    }
    await sessionStorage.setItem(
      payload.session_id || SessionKeys.DRESSING_ROOM,
      JSON.stringify(dr)
    )
    context.commit(MutationsNames.SET_DRESSING_ROOM_ELEMENTS, dr)
  },
  /**
   * @Actions
   * @description Action for add skus bulk, based on pk_consumer and sku
   * @payload SetSessionItemBulkD
   * @type void
   * @return void
   * */
  async [ActionsNames.SET_SESSION_ITEM_BULK](
    context,
    payload: SetSessionItemBulkD
  ): Promise<void> {
    const dr = payload?.product || {}
    const pk_consumer = payload?.pk_consumer || ''
    if (payload.sku.length) {
      if (!dr[pk_consumer]) {
        dr[pk_consumer] = []
      }
      payload.sku.forEach((s) => {
        const usr = dr[pk_consumer]
        const findIsSkuExist = usr?.find(
          (el: GetSessionElementD) => el.sku === s
        )
        if (findIsSkuExist) {
          const index = usr?.indexOf(findIsSkuExist)
          findIsSkuExist.quantity = findIsSkuExist.quantity + 1
          dr[pk_consumer].splice(index, 1, findIsSkuExist)
        } else {
          dr[pk_consumer].push({
            sku: s,
            quantity: 1,
          })
        }
      })
      delete dr['']
      await sessionStorage.setItem(
        payload.session_id || SessionKeys.DRESSING_ROOM,
        JSON.stringify(dr)
      )
      context.commit(MutationsNames.SET_DRESSING_ROOM_ELEMENTS, dr)
    }
  },
  /**
   * @Actions
   * @description Action for request model based on sku
   * @ReferGetters GetterNames.GET_SEARCHED_SKU_DATA
   * @payload { sku: string }
   * @type void
   * @return void
   * */
  async [ActionsNames.GET_REQUEST_SKU](
    context,
    payload: { sku: string; get_related: string }
  ) {
    try {
      const r = await catalogApi.apiV1PoswebModelsGet(
        payload.sku,
        payload.get_related ?? '0'
      )
      const attr: GetModelsAttributes | undefined = r.data.data?.length
        ? (r.data?.data || [])[0].attributes
        : {}
      context.commit(MutationsNames.SET_SHOW_LOADER_FULL, false)
      if (Object.keys(attr || {}).length) {
        context.commit(MutationsNames.SET_OPENED_PRODUCT_INFO_MODAL, true)
        context.commit(MutationsNames.SET_OPENED_DRAWER_OR_MODAL, true)
        context.commit(MutationsNames.SET_CURRENT_PRODUCT, {
          product: attr,
        })
      } else {
        context.commit(MutationsNames.SET_OPENED_DRAWER_OR_MODAL, false)
        // @ts-ignore
        await store.dispatch(
          NotificationsActions.NOTIFY_ERROR,
          I18n.global.t('pos_endless_aisle.no_products_found')
        )
      }
    } catch (err: unknown) {
      await handleCatchRequest(err)
    }
  },
  /**
   * @Actions
   * @description Action for set full loader
   * @ReferGetters GetterNames.GET_SHOW_LOADER_FULL
   * @payload boolean
   * @type void
   * @return void
   * */
  [ActionsNames.SET_SHOW_LOADER_FULL](context, payload: boolean): void {
    context.commit(MutationsNames.SET_SHOW_LOADER_FULL, payload)
    context.commit(MutationsNames.SET_OPENED_DRAWER_OR_MODAL, payload)
  },
  /**
   * @Actions
   * @description Action for set page in endless aisle
   * @ReferGetters GetterNames.GET_STEP_PAGE
   * @payload StepPageE
   * @type void
   * @return void
   * */
  [ActionsNames.SET_STEP_PAGE](context, payload: StepPageE): void {
    context.commit(MutationsNames.SET_STEP_PAGE, payload)
  },
  /**
   * @Actions
   * @description Action for set all filters by request filtered for store sign
   * @ReferGetters GetterNames.GET_FILTERS
   * @payload StepPageE
   * @type Promise<void>
   * @return Promise<void>
   * */
  async [ActionsNames.GET_FILTERS](
    context,
    payload: { filterCategory?: string; filterSubCategory?: string }
  ): Promise<void> {
    try {
      const store_sign_available =
        store.getters[ConfigGetters.GET_STORE_CONFIGS].AVAILABLE_BRANDS[0]
      const r = await catalogApi.apiV1PoswebAdvancedFiltersGet(
        store_sign_available,
        payload?.filterSubCategory ?? payload?.filterCategory
      )
      const data = r.data?.data || []
      context.commit(MutationsNames.SET_FILTERS, data)
    } catch (err: unknown) {
      await handleCatchRequest(err)
    }
  },
  /**
   * @Actions
   * @description Action for set all categories by request filtered for store sign
   * @ReferGetters GetterNames.GET_CATEGORIES
   * @payload StepPageE
   * @type Promise<void>
   * @return Promise<void>
   * */
  async [ActionsNames.GET_CATEGORIES](context): Promise<void> {
    try {
      const store_sign_available =
        store.getters[ConfigGetters.GET_STORE_CONFIGS].AVAILABLE_BRANDS[0]
      const r = await catalogApi.apiV1PoswebCategoriesGet(store_sign_available)
      const data = r.data?.data || []
      context.commit(MutationsNames.SET_CATEGORIES, data)
    } catch (err: unknown) {
      await handleCatchRequest(err)
    }
  },
  /**
   * @Actions
   * @description Action for get models filtered by params
   * @payload GetModelsFiltersE
   * @type Promise<Array<GetModelsAttributes | undefined> | undefined>
   * @return Array<GetModelsAttributes | undefined> | undefined
   * */
  async [ActionsNames.GET_MODELS](
    context,
    payload: GetModelsFiltersE
  ): Promise<Array<GetModelsAttributes | undefined> | undefined> {
    try {
      const m = await catalogApi.apiV1PoswebModelsGet(
        payload?.filterSku,
        payload?.filterGetRelated ?? '0',
        payload?.filterPkConsumer,
        payload?.filterBrand,
        payload?.filterCategoria,
        payload?.filterRighe || 20,
        payload?.filterPagina,
        payload?.filterModel,
        payload?.filterModelvariant,
        payload?.filterSizes,
        payload?.filterStock,
        payload?.filterName,
        payload?.filterEan,
        payload?.filterLikeMode,
        payload?.filterRfidStore,
        payload?.filterCodCategoria,
        payload?.filterCodSottocategoria,
        payload?.filterCollection,
        payload?.filterMainline,
        payload?.filterModelB2C,
        payload?.filterNewArrivals,
        payload?.filterOnSale,
        payload?.filterPrice,
        payload?.filterMinprice,
        payload?.filterMaxprice,
        payload?.filterYearSeason,
        payload?.filterStyleFilter,
        payload?.filterBodyLengthFilter,
        payload?.filterColorFilter,
        payload?.filterMaterialFilter,
        payload?.filterTypefilter,
        payload?.filterSleeveLengthFilter,
        payload.filterOccasionFilter
      )
      return m.data.data?.length ? m.data.data.map((d) => d.attributes) : []
    } catch (err: unknown) {
      await handleCatchRequest(err)
      return undefined
    }
  },
  /**
   * @Actions
   * @description Action for set modal filter open
   * @ReferGetters GetterNames.GET_OPENED_FILTERS_MODAL
   * @payload boolean
   * @type void
   * @return void
   * */
  [ActionsNames.SET_OPENED_FILTERS_MODAL](context, payload: boolean): void {
    context.commit(MutationsNames.SET_OPENED_FILTERS_MODAL, payload)
  },
  /**
   * @Actions
   * @description Action for set selected filters by category and sub category in modal filters
   * @ReferGetters GetterNames.GET_SELECTED_FILTERS
   * @payload SelectedFiltersE
   * @type void
   * @return void
   * */
  [ActionsNames.SET_SELECTED_FILTERS](
    context,
    payload: SelectedFiltersPayloadE
  ): void {
    context.commit(MutationsNames.SET_SELECTED_FILTERS, payload)
  },
  /**
   * @Actions
   * @description Action for remove selected filters by category and sub category in modal filters
   * @ReferGetters GetterNames.GET_SELECTED_FILTERS
   * @payload SelectedFiltersPayloadE
   * @type void
   * @return void
   * */
  [ActionsNames.REMOVE_FILTER_TAG](
    context,
    payload: SelectedFiltersPayloadE
  ): void {
    context.commit(MutationsNames.REMOVE_FILTER_TAG, payload)
  },
  /**
   * @Actions
   * @description Action for set product modal is opened
   * @ReferGetters GetterNames.GET_OPENED_PRODUCT_INFO_MODAL
   * @payload SelectedFiltersPayloadE
   * @type void
   * @return void
   * */
  [ActionsNames.SET_OPENED_PRODUCT_INFO_MODAL](
    context,
    payload: SelectedFiltersPayloadE
  ): void {
    context.commit(MutationsNames.SET_OPENED_PRODUCT_INFO_MODAL, payload)
  },
  /**
   * @Actions
   * @description Action for set ymal products
   * @payload string
   * @type void
   * @return void
   * */
  async [ActionsNames.GET_REQUEST_YMAL_SKU](context, payload: string) {
    try {
      const store_sign_available =
        store.getters[ConfigGetters.GET_STORE_CONFIGS].AVAILABLE_BRANDS[0]
      const r = await catalogApi.apiV1PoswebYmalGet(
        store_sign_available,
        payload
      )
      return r.data.data?.length ? r.data?.data?.map((el) => el.attributes) : []
    } catch (err: unknown) {
      await handleCatchRequest(err)
      return undefined
    }
  },
  /**
   * @Actions
   * @description Action for set active filters from modal filters
   * @payload { context: string; remove: boolean; value: FilterModalElementE }
   * @type void
   * @return void
   * */
  [ActionsNames.SET_ACTIVE_FILTER_MODAL](
    context,
    payload: { context: string; remove: boolean; value: FilterModalElementE }
  ) {
    context.commit(MutationsNames.SET_ACTIVE_FILTER_MODAL, payload)
  },
  /**
   * @Actions
   * @description Action for reset all active filters from modal filters
   * @type void
   * @return void
   * */
  [ActionsNames.RESET_ACTIVE_FILTER_MODAL](context) {
    context.commit(MutationsNames.RESET_ACTIVE_FILTER_MODAL)
  },
  /**
   * @Actions
   * @description Action for set are applied filters from modal filters
   * @payload boolean
   * @type void
   * @return void
   * */
  [ActionsNames.SET_APPLIED_FILTER_MODAL](context, payload: boolean) {
    context.commit(MutationsNames.SET_APPLIED_FILTER_MODAL, payload)
  },
  /**
   * @Actions
   * @description Action for reset state
   * @payload boolean
   * @type void
   * @return void
   * */
  [ActionsNames.RESET_ENDLESS_AISLE](context) {
    context.commit(MutationsNames.RESET_ENDLESS_AISLE)
  },
  /**
   * @Actions
   * @description Action set dressing room info based on pk_consumer
   * @payload { pk_consumer: string }
   * @type void
   * @return void
   * */
  async [ActionsNames.SET_DRESSING_ROOM_INFO](
    context,
    payload: { pk_consumer: string }
  ) {
    const sessionItem = await context.getters[GetterNames.GET_SESSION_ITEM]
    context.commit(
      MutationsNames.SET_DRESSING_ROOM_INFO,
      sessionItem[payload.pk_consumer || ''] || []
    )
  },
  /**
   * @Actions
   * @description close and reset endless Aisle
   * @type void
   * @return void
   * */
  [ActionsNames.CLOSE_ENDLESS_AISLE](context) {
    context.commit(MutationsNames.RESET_ENDLESS_AISLE)
    context.commit(MutationsNames.SET_OPENED_DRAWER_OR_MODAL, false)
    context.commit(MutationsNames.SHOW_ENDLESS_AISLE, false)
  },
  /**
   * @Actions
   * @description set element to pass in sell page
   * @payload Array<string>
   * @type void
   * @return void
   * */
  [ActionsNames.SET_ELEMENT_TO_SELL](context, payload: Array<string>) {
    context.commit(MutationsNames.SET_ELEMENT_TO_SELL, payload)
  },
  /**
   * @Actions
   * @description set element in session temp way only for check in sell
   * @payload Array<{}>
   * @type void
   * @return void
   * */
  async [ActionsNames.SET_TEMP_DRESSING_ROOM](context, payload) {
    const currentSessionDataString = await sessionStorage.getItem(
      SessionKeys.TEMP_DRESSING_ROOM
    )
    const currentSessionData = JSON.parse(currentSessionDataString ?? '{}')
    let newSessionData = payload

    if (Object.keys(currentSessionData).length > 0) {
      // Get skus from currentSessionData and from payload
      const currentSkus = new Set(
        Object.values(currentSessionData).flatMap((arrayItem) =>
          arrayItem.map((item) => item.sku)
        )
      )

      const payloadSkus = new Set(
        Object.values(payload).flatMap((arrayItem) =>
          arrayItem.map((item) => item.sku)
        )
      )

      // Merge the skus without duplicates
      const combinedSkus = Array.from(new Set([...currentSkus, ...payloadSkus]))

      // Build a new session object with the combined skus
      newSessionData = {
        [Object.keys(payload)[0]]: combinedSkus.map((sku) => ({ sku })),
      }
    }

    await sessionStorage.setItem(
      SessionKeys.TEMP_DRESSING_ROOM,
      JSON.stringify(newSessionData)
    )
  },
  /**
   * @Actions
   * @description remove element in session temp
   * @payload Array<{}>
   * @type void
   * @return void
   * */
  async [ActionsNames.REMOVE_TEMP_DRESSING_ROOM]() {
    await sessionStorage.removeItem(SessionKeys.TEMP_DRESSING_ROOM)
  },
  [ActionsNames.SET_CURRENT_PRODUCT](
    context,
    payload: {
      product: GetModelsAttributes
      variant?: string
    }
  ): void {
    context.commit(MutationsNames.SET_CURRENT_PRODUCT, payload)
  },
  [ActionsNames.SET_PREV_PRODUCT](context, payload: GetModelsAttributes): void {
    context.commit(MutationsNames.SET_PREV_PRODUCT, payload)
  },
  /**
   * @Getters
   * @description request for stock
   * @payload string
   * @type void
   * @return Promise<Array<StockD> | void>
   * */
  async [ActionsNames.STOCK_REQUEST](
    context,
    payload: string
  ): Promise<Array<StockD> | void> {
    try {
      const r = await endlessAisleApi.apiV1StockGet(payload, 0)
      return r.data.data?.map((data) => ({
        ...data.attributes,
        sku: data.id,
      }))
    } catch (err: unknown) {
      await handleCatchRequest(err)
    }
  },
  /**
   * @Actions
   * @description set temporary products in filters
   * @payload Array<{GetModelsAttributes}>
   * @type void
   * @return void
   * */
  [ActionsNames.SET_TEMP_PRODUCTS](
    context,
    payload: Array<GetModelsAttributes>
  ): void {
    context.commit(MutationsNames.GET_TEMP_PRODUCTS, payload)
  },
  [ActionsNames.SET_LEDWALL_INFO](
    context,
    payload: {
      value: LedWallEntitiesT | LedWallEntityD
      context: string
    }
  ): void {
    context.commit(MutationsNames.SET_LEDWALL_INFO, payload)
  },
  [ActionsNames.RESET_LEDWALL_INFO](context): void {
    context.commit(MutationsNames.RESET_LEDWALL_INFO)
  },
}
