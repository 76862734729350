import axios, { AxiosResponse } from 'axios'
import store from '@/store'
import { CDN_URL } from '@/configs'
import { isArray } from 'lodash'
import { ConfigActions } from '@/store/configs-store'
import { CdnManifest } from '@/interfaces/cdnManifest'

// Do Note: baseURL should be empty string
const apiClient = axios.create({
  baseURL: '',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: false,
})

/**
 * Retrieves the base path for the CDN, which includes the CDN URL and the brand from the Vuex store.
 * The resulting base path has any trailing slash removed.
 *
 * @returns {string} The base path for the CDN.
 */
const getCDNBasePath = (): string => {
  let cdnUrl = store.state.configs?.setup?.cdn_url || CDN_URL
  const brand = store.state.configs?.setup?.stores[0].store_sign

  // Strip the slash at the end if present
  if (cdnUrl.endsWith('/')) {
    cdnUrl = cdnUrl.replace(/\/$/g, '')
  }
  return `${cdnUrl}/${brand}`
}

/**
 * Retrieves the CDN manifest data, either from the Vuex store or by fetching it from the CDN.
 *
 * If the `storeCdnManifest` data is already present in the Vuex store, it will be returned.
 * Otherwise, it will fetch the `manifest.json` file from the CDN base path and store the data in the Vuex store.
 *
 * @returns {Promise<CdnManifest>} The CDN manifest data.
 */
const retrieveCDNManifest = async (): Promise<CdnManifest> => {
  if (Object.entries(store.state.configs?.storeCdnManifest).length !== 0) {
    return store.state.configs?.storeCdnManifest
  }
  const path = getCDNBasePath()
  const storeCdnManifest: AxiosResponse<CdnManifest> = await apiClient.get(
    `${path}/manifest.json`
  )

  store.dispatch(ConfigActions.GET_STORE_CDN_MANIFEST, storeCdnManifest.data)
  return storeCdnManifest.data
}

/**
 * Retrieves a value from the CDN based on the provided name.
 *
 * The name can be a single key or a dotted notation to access nested properties in the CDN manifest data.
 * The resulting image URLs will include the base path for the CDN.
 *
 * @param {string} name - The name or dotted notation to access the image(s) in the CDN manifest data.
 * @returns {Promise<string | string[]>} The image URL or an array of image URLs.
 *
 * es.:
 * const data = {'a': 1, 'b': { c: 2, d: 3}}
 * getPathFromCDN('a') // returns 1
 * getPathFromCDN('b.c') // returns 2
 *
 */
export const getValueFromCDN = async (
  name: string
): Promise<string | string[]> => {
  const manifestData: CdnManifest = await retrieveCDNManifest()

  let keys = name.split('.')
  return keys.reduce((a, c) => a[c], manifestData) as string | string[]
}

/**
 * Retrieves a path from the CDN based on the provided name.
 * It uses `getValueFromCDN` to retrieve the value of given node.
 *
 * @param {string} name - The name or dotted notation to access the image(s) in the CDN manifest data.
 * @returns {Promise<string | string[]>} The image URL or an array of image URLs.
 *
 * es.:
 * const data = {'a': '/folder/file.png' }
 * getPathFromCDN('a') // returns 'https://<cdn_url>/<brand>/folder/file.png
 *
 */
export const getPathFromCDN = async (
  name: string
): Promise<string | string[]> => {
  const path: string = getCDNBasePath()
  let data = await getValueFromCDN(name)

  if (!data) {
    return ''
  }

  // Strip initial slash from image path
  const stripSlash = (el: string) => {
    if (el.startsWith('/')) {
      return el.replace(/^\//g, '')
    }
    return el
  }

  if (isArray(data)) {
    return data.map((el: string) => `${path}/${stripSlash(el)}`)
  }

  return `${path}/${stripSlash(data)}`
}

export default {
  getLoginImages(): Promise<string[]> {
    return getPathFromCDN('login') as Promise<string[]>
  },
  // getFileFromCDN, ES: policy loyalty
}
