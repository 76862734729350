import { GetterTree } from 'vuex'
import { RootState } from '@/store'
import { State } from './_state'

export enum GetterNames {
  GET_STATUS_CHECK = 'get-status-check',
  GET_SELECTED_ADDRESS = 'get-selected-address',
}

export type Getters = {
  [GetterNames.GET_STATUS_CHECK](
    state: State,
    rootState: RootState
  ): State['statusValidateCheck']
  [GetterNames.GET_SELECTED_ADDRESS](
    state: State,
    rootState: RootState
  ): State['selectedAddress']
}

export const getters: GetterTree<State, RootState> = {
  [GetterNames.GET_STATUS_CHECK]: (
    state: State
  ): State['statusValidateCheck'] => state.statusValidateCheck,
  [GetterNames.GET_SELECTED_ADDRESS]: (
    state: State
  ): State['selectedAddress'] => state.selectedAddress,
}
