import { ActionTree } from 'vuex'
import { RootState } from '@/store'
import { State } from './_state'
import { MutationsNames } from '@/modules/address-validation/store/_mutations'
import { DeliveryDataD } from '@/components/drawer/extra-services/delivery-section/delivery-section.d'
export enum ActionsNames {
  SET_STATUS_CHECK = 'set-status-check',
  SET_SELECTED_ADDRESS = 'set-selected-addres',
  RESET_ADDRESS_VALIDATION = 'reset-address-validation',
}

export interface Actions {
  [ActionsNames.SET_STATUS_CHECK](payload: State['statusValidateCheck']): void
  [ActionsNames.SET_SELECTED_ADDRESS](payload: DeliveryDataD): void
  [ActionsNames.RESET_ADDRESS_VALIDATION](): void
}

export const actions: ActionTree<State, RootState> = {
  [ActionsNames.SET_STATUS_CHECK](
    context,
    payload: State['statusValidateCheck']
  ) {
    context.commit(MutationsNames.SET_STATUS_CHECK, payload)
  },
  [ActionsNames.SET_SELECTED_ADDRESS](context, payload: DeliveryDataD) {
    context.commit(MutationsNames.SET_SELECTED_ADDRESS, payload)
  },
  [ActionsNames.RESET_ADDRESS_VALIDATION](context) {
    context.commit(MutationsNames.RESET_ADDRESS_VALIDATION)
  },
}
