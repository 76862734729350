import {
  Module,
  mapActions as vuexMapActions,
  mapMutations as vuexMapMutations,
} from 'vuex'
import {
  TypedMapActions,
  TypedMapMutations,
  TypedStateStore,
} from '@/store/_types'
import { RootState } from '@/store'
import { State, initState } from './_state'
import {
  GetterNames,
  Getters,
  getters,
} from '@/modules/address-validation/store/_getters'
import {
  ActionsNames,
  Actions,
  actions,
} from '@/modules/address-validation/store/_actions'
import {
  Mutations,
  mutations,
} from '@/modules/address-validation/store/_mutations'

const addressValidationState: Module<State, RootState> = {
  namespaced: true,
  state: initState,
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export const NAMESPACE = 'addressValidation'

export type AddressValidationStore<State> = TypedStateStore<
  typeof NAMESPACE,
  State,
  Mutations,
  Actions,
  Getters
>

export const AddressValidationActions = {
  [ActionsNames.SET_STATUS_CHECK]: `${NAMESPACE}/${ActionsNames.SET_STATUS_CHECK}`,
  [ActionsNames.SET_SELECTED_ADDRESS]: `${NAMESPACE}/${ActionsNames.SET_SELECTED_ADDRESS}`,
  [ActionsNames.RESET_ADDRESS_VALIDATION]: `${NAMESPACE}/${ActionsNames.RESET_ADDRESS_VALIDATION}`,
}

export const AddressValidationMutations = {}

export const AddressValidationGetters = {
  [GetterNames.GET_STATUS_CHECK]: `${NAMESPACE}/${GetterNames.GET_STATUS_CHECK}`,
  [GetterNames.GET_SELECTED_ADDRESS]: `${NAMESPACE}/${GetterNames.GET_SELECTED_ADDRESS}`,
}

export const mapActions = vuexMapActions as TypedMapActions<
  typeof NAMESPACE,
  Actions
>

export const mapMutations = vuexMapMutations as TypedMapMutations<
  typeof NAMESPACE,
  Mutations
>

export * from './_mutations'
export * from './_actions'
export * from './_getters'
export type { State as AddressValidationState } from './_state'
export default addressValidationState
