<script lang="ts">
import { LaybyStatusType, PageContexts } from '@/addons/enums'
import FeBarcodeScannerControllerV2 from '@/modules/barcode-scanner/index'
import FeBarcodeScannerController from '@/components/global/barcodeScanner'
import { isLoginPage, isRoute, Routes, RoutesName } from '@/router'
import { SalesGetters, SalesActions } from '@/store/sales/sales-store'
import { RefundB2EListGetters } from '@/store/refund-b2e-list-store'
import { defineComponent } from 'vue'
import { DrawerActions } from '@/store/drawer-store'
import { RouteLocationNormalized, useRoute, useRouter } from 'vue-router'
import { mapGetters } from 'vuex'
import { IonIcon } from '@ionic/vue'
import FeDrawerLeft from '@/components/drawer-left.vue'
import FeCustomDateIcon from '@/components/tools/custom-date/icon-custom-date.vue'
import {
  notifications,
  notificationsOutline,
  bookmark,
  bookmarkOutline,
  home,
  homeOutline,
} from 'ionicons/icons'
import store from '@/store'
import { StoreStatusGetters } from '@/store/store-status-store'
import { MenuActions } from '@/store/menu-store'
import { getContextColor } from '@/addons/functions'
import { ContextPageGetters } from '@/store/context-page/context-page'
import { MenuPartsE, SidebarGetters } from '@/store/sidebar/index'
import {
  ActionsNames,
  EndlessAisleActions,
  EndlessAisleGetters,
  GetterNames,
  StepPageE,
} from '@/modules/endless-aisle/store/index'
import { isConfigTruthy } from '@/addons/functions/configs'

const LEFT_DRAWER_BOOKMARK = 'LEFT_DRAWER_BOOKMARK'
const LEFT_DRAWER_NOTIFICATIONS = 'LEFT_DRAWER_NOTIFICATIONS'

const LEFT_DRAWER_CONTENT = [
  {
    name: LEFT_DRAWER_BOOKMARK,
    title: 'pos_common.external_links',
    component: 'FeExternalLinks',
  },
  {
    name: LEFT_DRAWER_NOTIFICATIONS,
    title: 'pos_common.notify',
    component: 'FeLeftDrawerNotifications',
  },
]
export default defineComponent({
  name: 'FeSidebar',
  components: {
    IonIcon,
    FeDrawerLeft,
    FeCustomDateIcon,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    return {
      router,
      route,
      Routes,
      context: '',
      notifications,
      notificationsOutline,
      bookmark,
      bookmarkOutline,
      home,
      homeOutline,
    }
  },
  data() {
    return {
      currentLeftDrawer: '',
      isDrawerLeftOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      creditCardInserted: SalesGetters.GET_CREDIT_CARD_PAYMENT_VALID,
      giftCardInserted: SalesGetters.GET_GIFT_CARD_PAYMENT_VALID,
      creditCardOrGiftInsertedInReturns:
        SalesGetters.GET_CARDS_PAYMENT_IN_RETURNS_VALID,
      creditCardInsertedInRefundB2E:
        RefundB2EListGetters.GET_INSERT_CREDIT_CARD,
      isGiftCardSale: SalesGetters.GET_IS_GIFT_CARD_SALE,
      laybyStatus: SalesGetters.GET_LAYBY_STATUS,
      laybyStatusEqualTo: SalesGetters.GET_LAYBY_STATUS_EQUAL_TO,
      getContextPage: ContextPageGetters.GET_CONTEXT_PAGE,
      hasNotifications: StoreStatusGetters.GET_HAS_NOTIFICATIONS,
      getSideBarMenu: SidebarGetters.GET_SIDEBAR_MENU,
    }),
    getEndlessAisleOpen(): boolean {
      if (isConfigTruthy('ENDLESSAISLE_ENABLED')) {
        return store.getters[
          EndlessAisleGetters[GetterNames.SHOW_ENDLESS_AISLE]
        ]
      }
      return false
    },
    getEndlessAisleStepPage(): string {
      if (isConfigTruthy('ENDLESSAISLE_ENABLED')) {
        return store.getters[EndlessAisleGetters[GetterNames.GET_STEP_PAGE]]
      }
      return ''
    },
    isLoginPage(): boolean {
      return isLoginPage(this.route)
    },
    isDashboardPage(): boolean {
      return isRoute(Routes.DASHBOARD, this.route)
    },
    isStockPage(): boolean {
      return isRoute(Routes.STOCK, this.route)
    },
    isOlderPage(): boolean {
      return isRoute(Routes.PREVIOUS_UI_PAGES, this.route)
    },
    areLinksDisabled(): boolean {
      return (
        this.creditCardInserted ||
        this.giftCardInserted ||
        this.isGiftCardSale ||
        this.creditCardOrGiftInsertedInReturns ||
        this.creditCardInsertedInRefundB2E
      )
    },
    areLinksDisabledStock(): boolean {
      return (
        this.creditCardInserted ||
        this.giftCardInserted ||
        this.isGiftCardSale ||
        this.creditCardOrGiftInsertedInReturns ||
        this.creditCardInsertedInRefundB2E
      )
    },
    isNotificationOpen(): boolean {
      return this.currentLeftDrawer === LEFT_DRAWER_NOTIFICATIONS
    },
    isBookmarkOpen(): boolean {
      return this.currentLeftDrawer === LEFT_DRAWER_BOOKMARK
    },
    getDrawerTitle(): string {
      return (
        LEFT_DRAWER_CONTENT.find((el) => el.name === this.currentLeftDrawer)
          ?.title || ''
      )
    },
    drawerComponent(): string {
      return (
        LEFT_DRAWER_CONTENT.find((el) => el.name === this.currentLeftDrawer)
          ?.component || ''
      )
    },
    conditionalIconSideMenu() {
      return [
        ...this.getSideBarMenu[MenuPartsE.OPTIONAL],
        {
          component: 'fe-custom-date-icon',
        },
      ]
    },
  },

  watch: {
    $route(to: RouteLocationNormalized) {
      this.handlePathChanges(to)

      if (to.path === Routes.DASHBOARD) {
        this.resetCurrentTab()
      }
    },
    isDrawerLeftOpen(newValue) {
      if (newValue === false) {
        this.currentLeftDrawer = ''
      }
    },
  },

  mounted() {
    this.handlePathChanges(this.route)
  },

  methods: {
    getContextColor,
    resetCurrentTab() {
      store.dispatch(MenuActions.SET_CURRENT_TAB, 0)
    },
    closeDrawerHandler() {
      if (!this.areLinksDisabled) {
        this.isDrawerLeftOpen = false
        if (this.getEndlessAisleOpen) {
          store.dispatch(EndlessAisleActions[ActionsNames.CLOSE_ENDLESS_AISLE])
        }
      }
    },
    onBookmarkClick(): void {
      if (this.getEndlessAisleOpen) {
        store.dispatch(EndlessAisleActions[ActionsNames.CLOSE_ENDLESS_AISLE])
      }
      if (this.currentLeftDrawer === LEFT_DRAWER_BOOKMARK) {
        this.isDrawerLeftOpen = !this.isDrawerLeftOpen
      } else {
        this.isDrawerLeftOpen = true
        this.currentLeftDrawer = LEFT_DRAWER_BOOKMARK
      }
    },
    onNotificationsClick(): void {
      if (this.getEndlessAisleOpen) {
        store.dispatch(EndlessAisleActions[ActionsNames.CLOSE_ENDLESS_AISLE])
      }
      if (this.currentLeftDrawer === 'LEFT_DRAWER_NOTIFICATIONS') {
        this.isDrawerLeftOpen = !this.isDrawerLeftOpen
      } else {
        this.isDrawerLeftOpen = true
        this.currentLeftDrawer = LEFT_DRAWER_NOTIFICATIONS
      }
    },
    navigateBack(): void {
      // Unmount the barcode sc
      // anner if the back button has been clicked while it was open.
      if (FeBarcodeScannerController.isOpen()) {
        return FeBarcodeScannerController.unmountComponent()
      }
      if (FeBarcodeScannerControllerV2.isOpen()) {
        return FeBarcodeScannerControllerV2.unmountComponent()
      }

      if (this.getEndlessAisleOpen) {
        if (this.getEndlessAisleStepPage === StepPageE.INTRO) {
          store.dispatch(EndlessAisleActions[ActionsNames.CLOSE_ENDLESS_AISLE])
        } else {
          store.dispatch(
            EndlessAisleActions[ActionsNames.SET_STEP_PAGE],
            StepPageE.INTRO
          )
        }
        return
      }

      if (this.route.name === RoutesName.DASHBOARD_SUBMENU) {
        // reset currentTabIndex
        store.dispatch(DrawerActions.CLOSE)
        this.router.replace(Routes.DASHBOARD)
        return
      }

      if (this.laybyStatus) {
        if (this.laybyStatusEqualTo(LaybyStatusType.RECAP_VIEW_RESUME_DELETE)) {
          store.dispatch(
            SalesActions.SET_LAYBY_STATUS,
            LaybyStatusType.RECAP_DELETE
          )
        } else if (this.laybyStatusEqualTo(LaybyStatusType.RECAP_VIEW_RESUME)) {
          store.dispatch(
            SalesActions.SET_LAYBY_STATUS,
            LaybyStatusType.RECAP_RESUME
          )
        } else {
          store.dispatch(SalesActions.SET_LAYBY_STATUS, LaybyStatusType.RECAP)
        }
      }

      if (!this.areLinksDisabled) {
        if (this.isOlderPage) {
          // Older pages mess up with the history state. As a consequence, `.go(-1)` does not work.
          this.router.replace(history.state.back)
        } else {
          store.dispatch(DrawerActions.CLOSE)
          this.router.go(-1)
        }
      }
    },

    handlePathChanges(newPath: RouteLocationNormalized) {
      if (this.getContextPage === PageContexts.CUSTOM_DATE) {
        this.context = 'custom_date'
        return
      }
      switch (newPath.meta?.context) {
        case PageContexts.STOCK:
          this.context = 'stock'
          break
        case PageContexts.BACKOFFICE:
          this.context = 'backoffice'
          break
        case PageContexts.FRONTOFFICE:
          this.context = 'frontoffice'
          break

        default:
          this.context = ''
          break
      }
    },
  },
})
</script>

<template>
  <aside v-if="!isLoginPage">
    <nav
      class="links nav"
      :class="{ openDrawer: isNotificationOpen || isBookmarkOpen }"
    >
      <span
        class="link link--back"
        :class="{ disabled: areLinksDisabled }"
        @click.prevent="navigateBack"
      >
        <fe-icon class="link__icon" icon="chevron-left" />
      </span>

      <div class="links top" :class="{ disabled: areLinksDisabled }">
        <div class="top__notifcations">
          <div class="icon-container" :class="{ active: isNotificationOpen }">
            <ion-icon
              class="link"
              :icon="isNotificationOpen ? notifications : notificationsOutline"
              @click="onNotificationsClick"
            />
            <span
              v-show="hasNotifications"
              class="top__notifcations__badge"
            ></span>
          </div>
        </div>
      </div>

      <span class="links center">
        <router-link
          class="link link--dashboard"
          :to="areLinksDisabled ? '' : Routes.DASHBOARD"
          :class="{ disabled: areLinksDisabled }"
          @click.prevent="closeDrawerHandler"
        >
          <ion-icon
            class="link__icon__dashboard"
            :icon="isDashboardPage ? home : homeOutline"
          />
        </router-link>
        <router-link
          class="link link--dashboard"
          :to="areLinksDisabledStock ? '' : Routes.STOCK"
          :class="{ disabled: areLinksDisabledStock }"
          @click.prevent="closeDrawerHandler"
        >
          <fe-icon
            class="link__icon"
            :icon="isStockPage ? 'inventory_filled' : 'inventory'"
          />
        </router-link>
      </span>

      <div class="links bottom" :class="{ disabled: areLinksDisabled }">
        <div
          class="icon-container__Bookmark"
          :class="{ active: isBookmarkOpen }"
        >
          <ion-icon
            class="link"
            :icon="isBookmarkOpen ? bookmark : bookmarkOutline"
            @click="onBookmarkClick"
          />
        </div>
        <template v-for="(item, i) in conditionalIconSideMenu" :key="i">
          <component
            :is="item?.component"
            v-bind="{ ...item?.props }"
            :class="{ disabled: areLinksDisabled }"
            @click="isDrawerLeftOpen = false"
          />
        </template>
      </div>
    </nav>
    <fe-drawer-left
      v-model:model-value="isDrawerLeftOpen"
      :title="getDrawerTitle"
      :name="'menu-left'"
      :component="drawerComponent"
    >
    </fe-drawer-left>
  </aside>
</template>

<style lang="scss" scoped>
@import '@/styles/_constants.scss';
@import '@/styles/_mixins.scss';

$icon-height: 2rem;
$link-height: 4.2rem;

aside {
  display: flex;
  width: 6.4rem;
  flex-direction: column;
  justify-content: space-between;
  background-color: $color-white;

  & > nav {
    // This will prevent the sidebar from being resized when virtual keyboard is shown.
    height: 100vh;
  }
}

.links {
  &.disabled {
    &:deep(.link),
    &:deep(.disabled) {
      cursor: not-allowed;
      pointer-events: none;
    }
    &:deep(ion-icon) {
      color: $color-mid-grey;
    }
  }
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  @include z-index('modal', 2);
  background-color: $color-white;
  &.openDrawer {
    @include z-index('modal', -2);
  }
  &.nav {
    justify-content: space-between;
  }

  &.center {
    justify-content: center;

    & .link {
      &:not(:first-of-type) {
        margin-top: 2.5rem;
      }

      &:not(:last-of-type) {
        margin-bottom: 2.5rem;
      }
    }
  }
  &.top {
    margin-top: 3rem;
    justify-content: flex-start;
  }
  .top__notifcations {
    position: relative;
    display: inline-block;
    width: 100%;

    &__badge {
      position: absolute;
      top: 14px;
      right: 20px;
      padding: 5px 5px;
      border-radius: 50%;
      background: $color-selected-light;
    }
  }

  &.bottom {
    margin-bottom: 2rem;
    justify-content: end;

    .icon-container {
      flex: 1;
    }
  }

  .icon-container {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;

    &.active {
      background-color: white;
      box-shadow: 0 0 0.7rem 0 #aaa;
      @include z-index('modal', -2);
    }
    &__Bookmark {
      width: 100%;
      height: 50px;
      margin-bottom: 4.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
    }
  }
}

:deep(ion-icon.link__icon__dashboard) {
  color: $color-base;
}
.link {
  font-size: 2rem;
  width: $link-height;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;

    & .link__icon:deep(ion-icon) {
      color: $color-mid-grey;
    }

    &:deep(ion-icon.link__icon__dashboard) {
      color: $color-mid-grey;
    }
  }

  &__icon {
    height: $icon-height;
    width: $icon-height;

    &:deep(ion-icon) {
      color: $color-base;
    }

    &:deep(ion-icon) {
      color: $color-base;
    }

    &.stock:deep(ion-icon) {
      color: $color-stock;
    }

    &.backoffice:deep(ion-icon) {
      color: $color-backoffice;
    }

    &.frontoffice:deep(ion-icon) {
      color: $color-frontoffice;
    }
    &.custom_date:deep(ion-icon) {
      color: $color-custom-date;
    }
  }

  &--back {
    margin-top: 6.2rem;
  }

  &--menu {
    margin-bottom: 3rem;
  }
}

:deep(ion-menu) {
  --width: 50%;

  &:focus-visible {
    border: none;
  }
  top: 49px;
  left: 63px;
  box-shadow: -10px 0px 70px -1px #aaaaaa;
}
</style>
